import React from "react";

export default function About() {
  return (
    <div className="about">
      {/* {console.log("About Rander")} */}
      <ul>
        <li>Who am I?</li>
        <li>Why I'm exist?</li>
        <li>No Ans!</li>
      </ul>
    </div>
  );
}
